export const contractAddress =
  'erd1qqqqqqqqqqqqqpgqzua945mcn89996axjflrawkylma2yppk48ks0mr5z5';

// eslint-disable-next-line quotes
export const dAppName = "Morfilio's private Munchkin sale";

export const minValue = 0.02;
export const maxValue = 10;
export const tokenPrice = 50000000;
export const token = 'MUNCHKIN';
