/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { ReactComponent as HeartIcon } from '../../../assets/img/heart.svg';

const Footer = () => {
  return (
    <footer className='text-center mt-2 mb-3'>
      <div>
        Made with <HeartIcon className='mx-1' /> by Munchkin fan.
        <br />
        Contact{' '}
        <a href='https://t.me/morfilio' target='_blank'>
          @morfilio
        </a>{' '}
        on telegram for questions
      </div>
    </footer>
  );
};

export default Footer;
