import * as React from 'react';
import {
  useGetAccountInfo,
  transactionServices,
  refreshAccount
} from '@elrondnetwork/dapp-core';

import { Form } from 'react-bootstrap';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { contractAddress, minValue, maxValue, tokenPrice, token } from 'config';

const Actions = ({
  tokensLeft,
  setTransactionSessionId,
  setHasPendingTransactions
}: any) => {
  const [egldValue, setEgldValue] = React.useState<number>();
  const [receivedToken, setReceivedToken] = React.useState<number>(0);
  const { account } = useGetAccountInfo();
  const { sendTransactions } = transactionServices;

  const sendBuyTransaction = async () => {
    if (egldValue != undefined) {
      if (egldValue > 0) {
        const amount = 1000000000000000000 * egldValue;

        if (tokensLeft >= receivedToken) {
          const buyTransaction = {
            value: amount,
            data: 'buy',
            receiver: contractAddress
          };
          await refreshAccount();

          const { sessionId } = await sendTransactions({
            transactions: buyTransaction,
            // transactionsDisplayInfo: {
            //   processingMessage: 'Processing Sale',
            //   errorMessage: 'An error has occured during Sale',
            //   successMessage: 'You have been blessed by Munchkin'
            // },
            redirectAfterSign: false
            //callbackRoute: '/success'
          });
          if (sessionId != null) {
            setTransactionSessionId(sessionId);
            setHasPendingTransactions(true);
          }
        }
      }
    }
  };

  const handleChange = (e: any) => {
    const value = parseFloat(e.target.value);
    const balance = parseInt(account.balance) / 1000000000000000000;
    if (value > balance) {
      setEgldValue(balance - 0.001);
      if (value > maxValue) {
        setEgldValue(maxValue);
        return;
      }
      return;
    }
    setEgldValue(value);
  };

  React.useEffect(() => {
    if (egldValue) {
      setReceivedToken(egldValue * tokenPrice);
    } else {
      setReceivedToken(0);
    }
  }, [egldValue]);

  return (
    <Container>
      <Row>
        <Col>
          <div className='d-flex  justify-content-center form-width'>
            <Form.Group className='mb-3' controlId='formBuyAmount'>
              <Form.Label className='form-label-munchkin'>
                EGLD Amount
              </Form.Label>
              <Form.Control
                className='munchkin-input'
                type='number'
                value={egldValue}
                step={0.02}
                placeholder={minValue.toString() + ' - ' + maxValue.toString()}
                onChange={handleChange}
              />
            </Form.Group>
          </div>
          <div className='d-flex mt-4 justify-content-center form-width'>
            <Form.Group className='mb-3' controlId='formBuyAmount'>
              <Form.Label className='form-label-munchkin'>
                {token} to receive
              </Form.Label>
              <Form.Control
                className='munchkin-input'
                disabled
                value={receivedToken}
                type=''
                placeholder='0'
              />
            </Form.Group>
          </div>
          <div className='my-4'>
            <Button variant='info' size='lg' onClick={sendBuyTransaction}>
              BUY NOW
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Actions;
