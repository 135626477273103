import * as React from 'react';
import {
  transactionServices,
  useGetNetworkConfig
} from '@elrondnetwork/dapp-core';
import {
  Address,
  ContractFunction,
  ProxyProvider,
  Query
} from '@elrondnetwork/erdjs';
import { contractAddress } from 'config';
import Transactions from 'pages/Dashboard/Transactions';
import Actions from './Actions';
import TopInfo from './TopInfo';

const Dashboard = () => {
  const { network } = useGetNetworkConfig();

  const [munchkinsLeft, setMunchkinsLeft] = React.useState<number>();
  const [hasPendingTransactions, setHasPendingTransactions] =
    React.useState<number>();
  const [transactionSessionId, setTransactionSessionId] = React.useState<
    string | null
  >(null);

  React.useEffect(() => {
    const query = new Query({
      address: new Address(contractAddress),
      func: new ContractFunction('getBalanceAmount'),
      args: []
    });
    const proxy = new ProxyProvider(network.apiAddress);
    proxy
      .queryContract(query)
      .then(({ returnData }) => {
        const [encoded] = returnData;
        const decoded = Buffer.from(encoded, 'base64').toString('hex');
        console.log(decoded);
        console.log(parseInt(decoded, 16));
        setMunchkinsLeft(parseInt(decoded, 16));
      })
      .catch((err) => {
        console.error('Unable to call VM query', err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasPendingTransactions]);

  const transactionStatus = transactionServices.useTrackTransactionStatus({
    transactionId: transactionSessionId
  });

  React.useEffect(() => {
    console.log(transactionStatus);
  }, [transactionStatus]);

  return (
    <div className='container py-4'>
      <div className='row'>
        <div className='col-12 col-md-10 mx-auto'>
          <div className='card shadow-sm rounded border-0'>
            <div className='card-body p-1'>
              <div className='card rounded border-0 bg-primary'>
                <div className='card-body text-center p-4'>
                  <TopInfo munchkinsLeft={munchkinsLeft} />
                  <Actions
                    tokensLeft={munchkinsLeft}
                    setTransactionSessionId={setTransactionSessionId}
                    setHasPendingTransactions={setHasPendingTransactions}
                  />
                </div>
              </div>
              <Transactions
                transactionStatus={transactionStatus}
                munchkinsLeft={munchkinsLeft}
                setMunchkinsLeft={setMunchkinsLeft}
                setHasPendingTransactions={setHasPendingTransactions}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
